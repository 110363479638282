import * as bootstrap from 'bootstrap';
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    target: String,
    container: String
  }

  connect() {
    if (this.containerValue) {
      this.container = document.querySelector(this.containerValue);
    } else {
      this.container = this.element;
    }
    const scrollSpy = new bootstrap.ScrollSpy(this.container, {
      target: this.targetValue,
    })
  }
}
