import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {

  connect() {
    var settings = {};
    new TomSelect(this.element, settings);
  }

}
