import { Controller } from "@hotwired/stimulus"
import Treeselect from 'treeselectjs'

export default class extends Controller {
  static targets = ["input"];
  static values = {
    singleSelect: { type: Boolean, default: false },
    options: Array,
    openLevel: Number
  }

  connect() {
    const treeselect = new Treeselect({
      parentHtmlContainer: this.element,
      value: this.selectedValue(),
      options: this.optionsValue,
      isIndependentNodes: true,
      disabledBranchNode: false,
      openLevel: this.openLevelValue,
      placeholder: "",
      clearable: false,
      isSingleSelect: this.singleSelectValue,
      inputCallback: (value) => {
        if (this.singleSelectValue) {
          this.inputTarget.value = value;
        } else {
          this.inputTarget.value = value.join(",");
        }
        Turbo.navigator.submitForm(this.inputTarget.form, this.inputTarget);
      }
    });
  }

  selectedValue() {
    if (this.singleSelectValue) {
      return this.inputTarget.value;
    }
    else {
      return this.inputTarget.value.split(",");
    }
  }
}
